import React from "react";
import "./Content.css";
import book2 from "../assets/images/BOOK COLOR.png";
import book1 from "../assets/images/BOOK.png";

export const Content = () => {
  return (
    <div className=" bg-[#D4EBF8]">
      <div className="container mx-auto my-4">
        <h4 className="py-5">Latest From Tanu Publication Blog</h4>
        <div className="grid grid-cols-3 gap-4 mb-5" id="slide">
          <img className="" src={book2}></img>
          <img className="" src={book2}></img>
          <img className="" src={book2}></img>
        </div>
      </div>
      <hr className="py-9" />
      <h4 className="py-9">Our Top Books in 2024</h4>
      <div className="grid grid-cols-4 gap-4 ms-8" id="slide">
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src={book1} alt="Book 1" />
        </a>
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src={book1} alt="Book 2" />
        </a>
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src={book1} alt="Book 3" />
        </a>
        <a
          href=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="" src={book1} alt="Book 4" />
        </a>
      </div>
    </div>
  );
};
